/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

function Seo({ title, metaDescription, lang }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
      ]}
      link={[
        {
          rel: "shortcut, icon",
          type: "image/png",
          href: "/images/favicon/favicon.ico",
        },
        {
          rel: "apple-touch-icon",
          href: "/images/favicon/apple-touch-icon.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "76x76",
          href: "/images/favicon/apple-touch-icon-76x76.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "120x120",
          href: "/images/favicon/apple-touch-icon-120x120.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "152x152",
          href: "/images/favicon/apple-touch-icon-152x152.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/images/favicon/apple-touch-icon-180x180.png",
        },
      ]}
    />
  );
}

Seo.defaultProps = {
  title: "Fitplan",
  metaDescription: "Fitplan",
  lang: "en-US",
};

Seo.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
};

export default Seo;
